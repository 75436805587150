<template>
  <div>
    <form-section :title="$t('courierSet.labelSettingsName')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationData.labelFormat"
            :title=" $t('courierSet.labelFormat')"
            rules="required"
            :filed-items="labelFormats"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { labelFormats } from '@/constants/Speditors/UPS/LabelFormats.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      labelFormats,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationData() {
      return this.getConfigurationSet();
    },
  },
};
</script>

<style scoped>
</style>
